// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* not needed - frontkit has always included normalize */
/* @import-normalize; */

/* rest of app styles */
* {
  margin: 0;
}

.loader {
  font-size: 32px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,wDAAwD;AACxD,uBAAuB;;AAEvB,uBAAuB;AACvB;EACE,SAAS;AACX;;AAEA;EACE,eAAe;EACf,SAAS;EACT,kBAAkB;EAClB,QAAQ;EACR,gCAAgC;AAClC","sourcesContent":["/* not needed - frontkit has always included normalize */\n/* @import-normalize; */\n\n/* rest of app styles */\n* {\n  margin: 0;\n}\n\n.loader {\n  font-size: 32px;\n  left: 50%;\n  position: absolute;\n  top: 50%;\n  transform: translate(-50%, -50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
